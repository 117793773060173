.primary-background {
  background: $primary;
}

.change-password {
  img {
    width: 20rem;
    height: 15rem;
  }
  .form {
    width: 50%;
  }
}

.updated-password {
  img {
    width: 30rem;
    height: 28rem;
  }
  .content {
    width: 50%;
    p {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }
}
